@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

@import "./variables";
@import "./components/app";
@import "./components/header";
@import "./components/menu";
@import "./components/tooltip.scss";
@import "./components/unsplashGallery.scss";
@import "./components/canvas.scss";
@import "./components/toolbar.scss";

* {
  box-sizing: border-box;
}

body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  // background-color: $main-bg;
  background: $body-bg;
  // overflow: hidden;
}

svg {
  fill: $text-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $text-color;
  margin: 0;
}

p {
  margin: 0;
}

button {
  cursor: pointer;
  background: 0;
  border: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
}

input {
  color: $text-color;
}

input[type="number"] {
  color: #969696;
  transition: all 0.2s;
  &:focus {
    border: 0.156vw solid $text-color;
    outline: none;
    color: $text-color;
  }
  &:hover {
    color: $text-color;
  }
}

input[type="color"] {
  -webkit-appearance: none;
  border: none;
  width: 100%;
  height: 100%;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.3s;
  &:focus {
    outline: none;
  }
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
}

.hidden {
  visibility: hidden;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0.469vw;
  height: 0.469vw;
  background: $menu-bg;
  border-radius: 0.391vw;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: $text-color;
  border-radius: 0.391vw;
}

.slider {
  width: 100%;
  cursor: pointer;
  &__header {
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-bottom: 1.172vw;
  }
  &__input {
    text-align: right;
  }
  &__title {
    font-weight: 600;
  }
}

.colors {
  &__grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 2.5vw);
  }
  &__color_active {
    transform: scale(1.2);
    box-shadow: 0px 0px 10px #393939;
  }
}

.toggle-btn {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    font-weight: 600;
  }
}

.checkbox {
  display: inline-block;
  width: 2.734vw;
  height: 0.938vw;
  border-radius: 0.625vw;
  background: $border-color;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  user-select: none;
  transition: background 350ms ease;
  clear: none;
  outline: 0;
  text-align: center;
  -webkit-appearance: none;
  box-shadow: inset 0 0.078vw 0.156vw rgba(0, 0, 0, 0.1);
  &:before {
    content: "";
    display: block;
    width: 1.328vw;
    height: 1.328vw;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: -0.234vw;
    transition: all 350ms cubic-bezier(0, 0.95, 0.38, 0.98),
      background 150ms ease;
    background: rgba(0, 0, 0, 0.2);
    transform: translate3d(0, -50%, 0) scale(0);
  }
  &:after {
    content: "";
    display: block;
    width: 1.328vw;
    height: 1.328vw;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: -0.234vw;
    transition: all 350ms cubic-bezier(0, 0.95, 0.38, 0.98),
      background 150ms ease;
    background: $disabled-color;
    border: 0.078vw solid rgba(0, 0, 0, 0.1);
    transform: translate3d(0, -50%, 0);
  }
  &:checked {
    &:before {
      background: transparent;
      font-size: 0;
      line-height: 0.078vw;
      transform: translate3d(100%, -50%, 0) scale(1);
    }
    &:after {
      background: $text-color;
      transform: translate3d(100%, -50%, 0);
    }
  }
  &__disabled {
    cursor: no-drop;
  }
}

.disabled {
  fill: $disabled-color;
  opacity: 0.5;

  cursor: default;
  svg {
    fill: $disabled-color;
    opacity: 0.5;
  }
}

#root {
  .rc-slider-rail {
    position: absolute;
    width: 100%;
    background-color: $border-color;
    height: 0.313vw;
    border-radius: 0.469vw;
  }

  .rc-slider-handle {
    border: none;
    width: 1.09375vw;
    height: 1.09375vw;
    margin-top: -0.390625vw;
    &:active {
      box-shadow: none;
      border-color: none;
    }
  }

  .rc-slider-dot {
    border: none;
  }

  .rc-slider-track {
    background-color: $text-color;
    height: 0.3125vw;
  }
}
