.landing {
  height: 100%;
  background: linear-gradient(
    95deg,
    rgba(151, 77, 55, 1) 0%,
    rgba(24, 30, 65, 1) 50%
  );
  color: white;
  overflow: hidden;

  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
  }

  .button-link {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    text-align: center;
    border-radius: 0.75rem;
    background: #ffffff1a;
    text-decoration: none;
    color: #fff;
    @media (max-width: 640px) {
      font-size: 0.75rem;
    }
  }
  .btn_primary {
    color: #ffffff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    background: linear-gradient(
      193deg,
      rgba(183, 92, 255, 1) 15%,
      rgba(103, 26, 228, 1) 89%
    );
  }
  .btn_primary_outline {
    color: #ffffff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid
      linear-gradient(
        193deg,
        rgba(183, 92, 255, 1) 15%,
        rgba(103, 26, 228, 1) 89%
      );
  }

  .nft-item {
    padding: 1.25rem;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    background: #ffffff1a;
    .out-image {
      overflow: hidden;
      position: relative;
      margin-bottom: 0.75rem;
      width: 100%;
      border-radius: 0.5rem;
      img {
        width: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
    .info {
      display: flex;
      margin-bottom: 1.25rem;
      font-weight: 700;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 640px) {
        font-size: 0.75rem;
      }
    }
    .buy-now {
      width: 100%;
    }
  }
  .nft-item:hover {
    background: #ffffff66;
  }

  .modal {
    position: fixed;
    width: 80%;
    height: auto;
    max-width: 700px;
    max-height: 90%;
    overflow: auto;
    background-image: radial-gradient(at top left, #2d2d2d 0%, #191919 100%);
    border-radius: 20px;
    padding: 20px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    .modal-container {
      position: relative;
      width: 100%;
      .overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.2);
        position: absolute;
        border-radius: 10px;
      }
      .overlay.show-overlay {
        display: block;
      }
      .overlay.hide-overlay {
        display: none;
      }
      @keyframes innerSpinner {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(22.5deg);
        }
        100% {
          transform: rotate(45deg);
        }
      }
      .inner-spinner > div {
        transform-origin: 75px 75px;
        animation: innerSpinner 0.2s infinite linear;
      }
      .inner-spinner > div div {
        position: absolute;
        width: 15px;
        height: 90px;
        background: #6c5ecb;
        left: 75px;
        top: 75px;
        transform: translate(-50%, -50%);
      }
      .inner-spinner > div div:nth-child(1) {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
      .inner-spinner > div div:nth-child(6) {
        width: 35px;
        height: 35px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 50%;
      }
      .inner-spinner > div div:nth-child(3) {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      .inner-spinner > div div:nth-child(4) {
        transform: translate(-50%, -50%) rotate(90deg);
      }
      .inner-spinner > div div:nth-child(5) {
        transform: translate(-50%, -50%) rotate(135deg);
      }
      .loading-spinner {
        width: 150px;
        height: 150px;
        display: inline-block;
        overflow: hidden;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .inner-spinner {
        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0) scale(1);
        backface-visibility: hidden;
        transform-origin: 0 0; /* see note above */
      }
      .inner-spinner div {
        box-sizing: content-box;
      }
      .close {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        color: #fff;
        font-size: 14px;
      }
      .heading {
        text-align: center;
        color: #fff;
        padding: 10px;
        font-size: 28px;
        @media (max-width: 640px) {
          font-size: 18px;
        }
      }
      .connection {
        display: flex;
        flex-direction: column;
        padding: 10px;
        .row {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding: 10px;
          @media (max-width: 640px) {
            flex-direction: column;
          }
          label {
            font-size: 14px;
            width: 30%;
            @media (max-width: 640px) {
              width: 100%;
              margin-bottom: 5px;
            }
            color: #fff;
          }
          span {
            font-size: 14px;
            color: #fff;
            width: 60%;
            @media (max-width: 640px) {
              width: 100%;
            }
          }
        }
      }
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-items: flex-start;
        @media (max-width: 640px) {
          flex-direction: column;
        }
        .left {
          display: flex;
          width: 50%;
          padding: 10px;
          height: 250px;
          @media (max-width: 640px) {
            width: 100%;
            height: 200px;
          }
          .preview {
            color: #000;
            display: flex;
            width: 100%;
            padding: 10px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
            .column {
              width: 100%;
              height: 100%;
              .img-button {
                width: 100%;
                height: 100%;
                background: #fff;
                color: grey;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
              }
              .invalid-feedback {
                color: red;
                margin-top: 5px;
              }
            }
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 50%;
          padding: 10px;
          @media (max-width: 640px) {
            width: 100%;
          }
          .column {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            padding: 10px;
            font-size: 14px;
            .input {
              width: 100%;
              height: 35px;
              border: none;
              outline: none;
              padding-left: 20px;
              box-sizing: border-box;
              color: #000;
            }
            .text-area {
              height: 150px;
              width: 100%;
              border: none;
              outline: none;
              padding: 20px;
              box-sizing: border-box;
              @media (max-width: 640px) {
                width: 100%;
                height: 100px;
              }
            }
            .invalid-feedback {
              color: red;
              margin-top: 5px;
            }
          }
        }
        .column {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          a {
            font-size: 14px;
            margin: 20px;
            color: #fff;
          }
        }
      }
      .footer {
        padding: 10px;
        display: flex;
        justify-content: space-evenly;
        button {
          font-size: 18px;
          width: 100px;
          @media (max-width: 640px) {
            font-size: 14px;
            width: 85px;
          }
        }
      }
    }
  }
  .modal.show-modal {
    display: block;
  }
  .modal.hide-modal {
    display: none;
  }

  .container {
    max-width: 1320px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    @media (min-width: 768px) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
    margin-left: auto;
    margin-right: auto;
  }

  .ld-navbar {
    padding-top: 2rem;
    padding-bottom: 1rem;
    position: relative;

    .navbar-container {
      display: flex;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 700;
        display: flex;

        p.sec {
          color: #b75cff;
        }
      }
      .case-one {
        display: none;
        @media (min-width: 640px) {
          display: block;
        }
      }
      .case-two {
        display: block;
        cursor: pointer;

        @media (min-width: 640px) {
          display: none;
        }
      }
      .fade {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 20;
        padding-top: 5rem;
        padding-bottom: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-right-radius: 1.5rem;
        border-bottom-left-radius: 1.5rem;
        background: #000000e6;
      }
      .sidebar {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (min-width: 640px) {
          flex-direction: row;
        }
        a {
          margin-right: 0;
          margin-left: 0;
          margin-bottom: 0.75rem;
          color: #fff;
          text-decoration: none;
          font-size: 0.75rem;
          @media (min-width: 640px) {
            margin-bottom: 0;
            margin-right: 1.75rem;
            margin-left: 1.75rem;
          }
        }
        .art-app-btn {
          margin-bottom: 0.75rem;
          @media (min-width: 640px) {
            margin-bottom: 0;
            margin-right: 1.75rem;
          }
        }
      }
    }
  }

  .ld-hero {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 5rem;
    flex-direction: column;
    @media (min-width: 640px) {
      flex-direction: row;
      margin-top: 5rem;
      margin-bottom: 8rem;
    }
    .left-side {
      padding-right: 0;
      @media (min-width: 640px) {
        padding-right: 2.5rem;
        margin-top: 1.25rem;
        width: 50%;
      }
      @media (min-width: 1024px) {
        padding-right: 5rem;
        margin-top: 2.5rem;
      }
      h1 {
        margin-bottom: 1.25rem;
        font-size: 1.875rem;
        line-height: 2.25rem;
        font-weight: 700;
        @media (min-width: 1024px) {
          font-size: 3.75rem;
          line-height: 1;
        }
      }
      p {
        margin-bottom: 2rem;
        opacity: 0.5;
        @media (max-width: 640px) {
          font-size: 0.75rem;
        }
      }
      .button-group {
        display: flex;
        align-items: center;
        margin-bottom: 2.5rem;
        .button-one {
          margin-right: 0.75rem;
        }
      }
      .statistic {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 0.75rem;
        .top {
          margin-bottom: 0.25rem;
          font-size: 1.5rem;
          line-height: 2rem;
          font-weight: 700;
        }
        .down {
          opacity: 0.5;
        }
      }
    }
    .right-side {
      margin-top: 1rem;
      flex: 1 1 0%;
      @media (min-width: 640px) {
        margin-top: 0;
      }
      .outdiv {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        @media (min-width: 1024px) {
          height: 500px;
        }
        img {
          @media (max-width: 640px) {
            width: 100%;
          }
        }
      }
    }
  }

  .ld-sponsor {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    @media (min-width: 640px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8rem;
    }

    .out-image {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 60px;
      @media (max-width: 640px) {
        margin-bottom: 2rem;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .ld-about-us {
    margin-bottom: 11rem;
    scroll-margin-top: 2.5rem;
    @media (max-width: 640px) {
      margin-bottom: 2rem;
    }
    .heading {
      margin-bottom: 2rem;
      text-align: center;
      h2 {
        margin-bottom: 0.5rem;
        font-size: 3rem;
        line-height: 1;
        font-weight: 700;
      }
    }
    .content {
      display: flex;
      align-items: center;
      .left-side {
        display: none;
        width: 44%;
        @media (min-width: 640px) {
          display: block;
        }
        .out-image {
          overflow: hidden;
          position: relative;
          width: 100%;
          border-radius: 1rem;
          height: 700px;
          img {
            object-fit: contain;
            object-position: center;
            width: 100%;
          }
        }
      }
      .right-side {
        margin-left: 0;
        flex: 1 1 0%;
        @media (min-width: 640px) {
          margin-left: 2.5rem;
        }
        @media (min-width: 1024px) {
          margin-left: 5rem;
        }
        h3 {
          margin-bottom: 0.75rem;
          font-size: 1.5rem;
          line-height: 2rem;
          font-weight: 700;
        }
        p {
          @media (max-width: 640px) {
            font-size: 0.75rem;
          }
          margin-bottom: 0.75rem;
          opacity: 0.5;
        }
      }
    }
  }

  .ld-collections {
    margin-bottom: 11rem;
    scroll-margin-top: 2.5rem;
    @media (max-width: 640px) {
      margin-bottom: 2rem;
    }
    .heading {
      margin-bottom: 2rem;
      text-align: center;
      h2 {
        margin-bottom: 0.5rem;
        font-size: 3rem;
        line-height: 1;
        font-weight: 700;
      }
    }
    .button-group {
      display: grid;
      margin-bottom: 1.25rem;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 1rem;
      @media (min-width: 640px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
      .select-btn {
        transition-property: background-color, border-color, color, fill, stroke,
          opacity, box-shadow, transform;
        transition-duration: 1000ms;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        width: 100%;
      }
    }
    .item-group {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 1rem;
      @media (min-width: 640px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      @media (min-width: 768px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }
  }

  .ld-getready {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
    margin-bottom: 11rem;
    background-image: linear-gradient(to bottom, #b75cff, #671ae4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.75rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    @media (max-width: 640px) {
      margin-bottom: 2rem;
    }
    h2 {
      margin-bottom: 1.25rem;
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 700;
      line-height: 1.375;
      text-align: center;
      width: 100%;

      @media (min-width: 640px) {
        font-size: 2.25rem;
        line-height: 2.5rem;
      }
      @media (min-width: 768px) {
        width: 40%;
      }
      @media (min-width: 1024px) {
        font-size: 3rem;
        line-height: 1;
      }
    }
  }

  .ld-footer {
    .top {
      margin-bottom: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 640px) {
        font-size: 0.75rem;
      }
      .one {
        margin-right: 0.25rem;
        font-weight: 700;
      }
      .two {
        margin-left: 0.25rem;
        font-weight: 700;
      }
    }
    .scroll-btn {
      background-image: linear-gradient(to bottom, #b75cff, #671ae4);
      animation: bounce 1s infinite;
      @keyframes bounce {
        0%,
        100% {
          transform: translateY(-25%);
          animationtimingfunction: cubic-bezier(0.8, 0, 1, 1);
        }
        50% {
          transform: translateY(0);
          animationtimingfunction: cubic-bezier(0, 0, 0.2, 1);
        }
      }
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 9999px;
      cursor: pointer;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
      margin: 0 auto;
      @media (max-width: 640px) {
        font-size: 0.75rem;
      }
    }
  }
}
