@import "../variables";

.unsplash-gallery {
  &__form {
    display: flex;
    border-bottom: 0.078vw solid $border-color;
    padding-bottom: 0.781vw;
  }
  &__submit-btn {
    padding-right: 0.781vw;
    svg {
      fill: transparent;
      stroke: $text-color;
      width: 1.563vw;
      height: 1.563vw;
      margin-bottom: -0.313vw;
    }
  }
  &__input {
    width: 100%;
    border: 0;
    padding: 0;
    background: transparent;
    &:focus {
      outline: none;
    }
  }
  &__navbar {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 1.953vw 0;
    button {
      color: $text-color;
      font-size: .95em;
    }
  }
  &__next-btn {
    justify-self: end;
    grid-column-start: 2;
  }
  &__prev-btn {
    justify-self: start;
    grid-column-start: 1;
  }
  &__error {
    color: $text-color;
    padding: 1.172vw 0;
    font-size: .95em;
    text-align: center;
  }
  &__loader {
    text-align: center;
    padding-top: 3.906vw;
    color: $text-color;
    svg {
      width: 6.25vw;
      height: 6.25vw;
    }
  }
  &__container.toolbar__content {
    padding-left: 0;
    padding-right: 0;
  }
}