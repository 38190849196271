.canvas {
  grid-column-start: 2;
  grid-row-start: 2;
  grid-column-end: 17;
  text-align: center;
  display: grid;
  overflow: auto;
  transition: all 600ms ease-in-out;
  transform: translateX(0);
  width: 100%;
  height: 88vh;

  &-container {
    align-self: center;
    justify-self: center;
  }
  &_toolbar-open {
    transform: translateX(25%);
    width: calc(100vw - 100vw / 16 * 4);
  }
}