@import "../variables.scss";

.menu {
  grid-row-start: 1;
  grid-row-end: 3;
  // background-color: $menu-bg;
  z-index: 2;
  display: flex;
  align-items: center;
  &__wrapper {
    width: 100%;
  }
  &__item {
    padding: 1.875vw;
    cursor: pointer;
    svg {
      width: 2.34375vw;
      height: 2.34375vw;
    }
    &:hover {
      background: $toolbar-bg;
      transition-duration: 0.3s;
    }
    &_active {
      background-color: $toolbar-bg;
    }
  }
}
