$main-bg: #1e1e1e;
$body-bg: linear-gradient(95deg, rgba(151, 77, 55, 1) 0%, #181e41 50%);
$menu-bg: #262626;
// $toolbar-bg: #313131;
$toolbar-bg: #181e41;
// $disabled-color: #606060;
$disabled-color: #fff;
$border-color: #3f3f3f;
$text-color: #eaeaea;

$base-font-family: "Open Sans", sans-serif;
$base-font-size: 1.25vw;
